$primaryColor: #00559a;
$primaryColorLight: rgb(227, 235, 248);
$secondaryColor: rgb(0, 172, 109);
$backgroundColor: white;
$innerCardColor: #f9f9f9;
$formLabel: rgba(0, 0, 0, 0.23);
$lightGray: rgb(253, 253, 253);

html,
body,
#root,
.App,
.AppWrapper {
  min-height: 100vh;
}

html {
  background-color: $backgroundColor;
}

.backgroundLighter {
  background-color: $lightGray;
}

#root {
  background-color: $backgroundColor !important;
}

/* BUTTONS & Links*/
.btn-primary {
  background-color: $primaryColor;
  border-color: $primaryColor;
}

.btn-primary:hover {
  background-color: $primaryColor;
  border-color: $primaryColor;
}

.btn-secondary {
  background-color: $secondaryColor;
  border-color: $secondaryColor;
}

.btn-secondary:hover {
  background-color: $secondaryColor;
  border-color: $secondaryColor;
}

/*BACKGROUND*/
.bg-primary {
  border-color: $primaryColor;
}

/* HEADER / NAV */

nav {
  z-index: 100;
  border-bottom: 1px solid #eeeef0;
}

/* CARD*/
.card-header {
  background-color: transparent;
}

/* FOOTER */

footer {
  color: black;
  a,
  a:hover {
    color: black;
  }
  border-top: 1px solid #eeeef0;
}

.card {
  border: none;
}

/*INPUT FORM SELECT*/

input,
select {
  height: 3.5rem !important;
}

.form-label {
  color: $formLabel !important;
}

/* SPAN */
span {
  word-break: break-all;
}

div.legalCheckbox div.invalid-feedback {
  width: 0;
}

.claim {
  .card {
    border-style: solid;
    border-color: $primaryColor;
  }
}
