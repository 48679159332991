$primaryColor: #00559a;
$primaryColorLight: rgb(227, 235, 248);
$secondaryColor: rgb(0, 172, 109);
$backgroundColor: white;
$innerCardColor: #f9f9f9;

.card-header {
  background-color: $primaryColor;
  color: white;
}

.btn-primary {
  background-color: $primaryColor;
}

.bg-primary {
  border-color: $primaryColor;
}

.btn-secondary {
  background-color: $secondaryColor;
  border-color: $secondaryColor;
}

.btn-secondary:hover {
  background-color: $secondaryColor;
  border-color: $secondaryColor;
}

.card-footer {
  background-color: $primaryColorLight;
}

/* ERROR MESSAGES*/
.error {
  color: red;
  font-size: small;
  font-weight: bold;
}

#submitSearchButton {
  height: fit-content;
}

.legalCheckbox.form-check.form-check-inline > label {
  margin-left: 16px;
}

.legalCheckbox.form-check.form-check-inline > input {
  margin-right: 16px;
}

.legalCheckbox > .invalid-feedback {
  width: auto !important;
}

.overlay {
  z-index: 1040;
  background-color: rgba(16, 16, 16, 0.5);
}

.overlay > div {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
}
.overlay > div > div {
  background-color: #00559a;
}

/* DISPLAY QUOTE*/

.fa-check-circle {
  color: green;
}
.card-footer {
  background: transparent;
  border-top: none;
}

.list-group-item {
  padding: 0.5rem 1.25rem;
}

.info-circle {
  color: $primaryColor;
}

::placeholder {
  opacity: 0.5 !important;
}
