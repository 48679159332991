.bottom-button {
  margin-bottom: 120px;
}

/*Only display the Progress stepper label on MD devices and above. Hide it on small devices*/
@media (max-width: 576px) {
  /* Target devices wider than 768px. */
  .MuiStepLabel-labelContainer {
    display: none;
  }
}

@media (min-width: 768px) {
  /* Target devices wider than 768px. */
  .MuiStepLabel-labelContainer {
    display: block;
  }
}
