/*Mimic bootstrap 5 spacing rules*/
@media (min-width: 992px) {
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 991px) {
  .w-md-100 {
    flex: 1;
    width: 100%;
  }
}
