$primaryColor: #00559a;
$primaryColorLight: rgb(227, 235, 248);
$secondaryColor: rgb(0, 172, 109);
$backgroundColor: aliceblue;
$innerCardColor: #f9f9f9;

.card-header {
  background-color: $primaryColor;
  color: white;
}

.btn-primary {
  background-color: $primaryColor;
}

.bg-primary {
  border-color: $primaryColor;
}

.btn-secondary {
  background-color: $secondaryColor;
  border-color: $secondaryColor;
}

.btn-secondary:hover {
  background-color: $secondaryColor;
  border-color: $secondaryColor;
}

.card-footer {
  background-color: $primaryColorLight;
}

/* ERROR MESSAGES*/
.error {
  color: red;
  font-size: small;
  font-weight: bold;
}

#submitSearchButton {
  height: fit-content;
}

.legalCheckbox.form-check.form-check-inline > label {
  margin-left: 16px;
}

.legalCheckbox.form-check.form-check-inline > input {
  margin-right: 16px;
}

.input {
  color: black;
}

//Header
.react-datepicker__header {
  background-color: $primaryColor;
  font-weight: bold;
}
// Header font
.react-datepicker__day-name,
.react-datepicker__current-month,
.react-datepicker-time__header {
  color: white;
}
// Selected day
.react-datepicker__day--selected {
  background-color: $primaryColor;
}

.react-datepicker__year-read-view--selected-year {
  color: white;
}

.react-datepicker-wrapper {
  width: 100%;
}
