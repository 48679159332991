@import './../Static//Base';

.MuiPaper-root {
  padding-left: 0px;
  padding-right: 0px;
}

.MuiStepIcon-root {
  color: rgba(0, 0, 0, 0.08);
  display: block;
}

.MuiStepLabel-label {
  color: rgba(0, 0, 0, 0.3);
  font-size: 1rem !important;
  font-weight: 700 !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: $primaryColor;
}

.MuiStepLabel-label.MuiStepLabel-active {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

.MuiTypography-body2 {
  font-size: 1rem;
}
